<template>
  <div class="auth">
    <Header :title="'实名认证'" :back="'home'"/>
    <div class="flex-box">
      <div>真实姓名</div>
      <div>{{ formatHide(info.name) }}</div>
    </div>
    <div class="flex-box">
      <div>证件类型</div>
      <div>身份证</div>
    </div>
    <div class="flex-box">
      <div>证件号码</div>
      <div>{{ formatHide(info.idcard )}}</div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return{
      info:''

    }
  },
  created(){
    if(this.$route.query.refer=='auth'){
      this.$store.dispatch('transation/getUserAmount',{params:{code:'my',type:'info'}}).then(res=>{
        this.info =res.info
      })
    }else{
      this.info = JSON.parse(localStorage.getItem('info'))
    }
    
  }
}
</script>
<style lang="scss" scoped>
.auth{
  .flex-box{
    border-bottom:1px solid #e4e4e4;
    line-height: 2;
    background: #ffff;
    padding: 5px 10px;
    justify-content: space-between;
    :last-child{
      color: #464646;
    }
  }
}
</style>