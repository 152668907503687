var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth" },
    [
      _c("Header", { attrs: { title: "实名认证", back: "home" } }),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", [_vm._v("真实姓名")]),
        _c("div", [_vm._v(_vm._s(_vm.formatHide(_vm.info.name)))]),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", [_vm._v("证件号码")]),
        _c("div", [_vm._v(_vm._s(_vm.formatHide(_vm.info.idcard)))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("证件类型")]),
      _c("div", [_vm._v("身份证")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }